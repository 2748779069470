<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div
                            class="grow text-xs-center"
                            :style="[eventSearch ? 'opacity: 0' : 'opacity: 1']"
                        >
                            SỬA KẾ HOẠCH
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                v-if="!eventSearch"
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="pa-2">
                <DxValidationGroup ref="formValidation">
                    <div class="row mt-1 mb-4">
                        <div class="xs12">
                            <DxTextBox
                                label="Giờ xuất bến"
                                labelMode="floating"
                                styling-mode="underlined"
                                :value="
                                    (ThongTinSuaChuyenDiKeHoach.GioXuatBen || '').substr(
                                        0,
                                        5,
                                    )
                                "
                                :readOnly="true"
                            />
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextArea
                                ref="TuyenVanChuyen"
                                label="Tuyến vận chuyển"
                                labelMode="floating"
                                styling-mode="underlined"
                                :value="`${ThongTinSuaChuyenDiKeHoach.TenBenXeDi} - ${ThongTinSuaChuyenDiKeHoach.TenBenXeDen} (${ThongTinSuaChuyenDiKeHoach.MaTuyen})`"
                                :readOnly="true"
                                :autoResizeEnabled="true"
                            />
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextArea
                                ref="TenDoiXe"
                                label="Tên đội xe"
                                labelMode="floating"
                                styling-mode="underlined"
                                :value="`${ThongTinSuaChuyenDiKeHoach.TenDoiXe}`"
                                :readOnly="true"
                                :autoResizeEnabled="true"
                            />
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxSelectBox
                                label="Xe thực hiện (*)"
                                labelMode="floating"
                                v-model="ThongTinSuaChuyenDiKeHoach.XeThucHien"
                                :dataSource="{
                                    store: DanhSachXeTrongDoi,
                                    paginate: true,
                                }"
                                display-expr="BienKiemSoat"
                                value-expr="IdDnvtXe"
                                :search-enabled="true"
                                :showClearButton="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                            >
                                <DxValidator ref="validateBienKiemSoat">
                                    <DxRequiredRule
                                        message="Xe thực hiện không được bỏ trống!"
                                    />
                                </DxValidator>
                            </DxSelectBox>
                        </div>
                    </div>

                    <div class="row align-center mb-4">
                        <div class="xs12">
                            <DxSelectBox
                                v-model="
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[0]
                                "
                                ref="BienKiemSoat"
                                label="Lái xe 1"
                                labelMode="floating"
                                :dataSource="{
                                    store: DanhSachLaiXeTrongDoi,
                                    paginate: true,
                                }"
                                display-expr="HoTen"
                                :search-enabled="true"
                                :showClearButton="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                item-template="item"
                                field-template="field"
                            >
                                <template #field="{ data }">
                                    <DxTextBox
                                        :value="data ? data.HoTen : ``"
                                        :class="
                                            CheckDuDieuKienLaiXeDi_1 == 'HetHan'
                                                ? 'color-error-thong-tin-lai-xe'
                                                : CheckDuDieuKienLaiXeDi_1 == 'GanHetHan'
                                                ? 'color-warning-thong-tin-lai-xe'
                                                : ''
                                        "
                                    />
                                </template>
                                <template #item="{ data }">
                                    <div
                                        style="
                                            white-space: break-spaces;
                                            border-bottom: 1px solid #dadce0;
                                        "
                                    >
                                        {{ data ? `${data.HoTen}` : "" }}
                                    </div>
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        v-if="
                                            !ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[1] &&
                                            !ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[2]
                                        "
                                        message="Lái xe không được bỏ trống!"
                                    />
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[2]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[2];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[1]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[1];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                </DxValidator>
                            </DxSelectBox>
                        </div>

                        <DxButton
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-pencil"
                            :disabled="
                                !ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[0]
                            "
                            @click="
                                SuaLaiXe(
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[0],
                                )
                            "
                        />
                    </div>
                    <div class="row align-center mb-4">
                        <div class="xs12">
                            <DxSelectBox
                                v-model="
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[1]
                                "
                                ref="BienKiemSoat"
                                label="Lái xe 2"
                                labelMode="floating"
                                :dataSource="{
                                    store: DanhSachLaiXeTrongDoi,
                                    paginate: true,
                                }"
                                display-expr="HoTen"
                                :search-enabled="true"
                                :showClearButton="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                item-template="item"
                                field-template="field"
                            >
                                <template #field="{ data }">
                                    <DxTextBox
                                        :value="data ? data.HoTen : ``"
                                        :class="
                                            CheckDuDieuKienLaiXeDi_2 == 'HetHan'
                                                ? 'color-error-thong-tin-lai-xe'
                                                : CheckDuDieuKienLaiXeDi_2 == 'GanHetHan'
                                                ? 'color-warning-thong-tin-lai-xe'
                                                : ''
                                        "
                                    />
                                </template>
                                <template #item="{ data }">
                                    <div
                                        style="
                                            white-space: break-spaces;
                                            border-bottom: 1px solid #dadce0;
                                        "
                                    >
                                        {{ data ? `${data.HoTen}` : "" }}
                                    </div>
                                </template>
                                <DxValidator>
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[0]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[0];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[2]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[2];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                </DxValidator>
                            </DxSelectBox>
                        </div>

                        <DxButton
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-pencil"
                            :disabled="
                                !ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[1]
                            "
                            @click="
                                SuaLaiXe(
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[1],
                                )
                            "
                        />
                    </div>
                    <div class="row align-center mb-4">
                        <div class="xs12">
                            <DxSelectBox
                                v-model="
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[2]
                                "
                                ref="BienKiemSoat"
                                label="Lái xe 3"
                                labelMode="floating"
                                :dataSource="{
                                    store: DanhSachLaiXeTrongDoi,
                                    paginate: true,
                                }"
                                display-expr="HoTen"
                                :search-enabled="true"
                                :showClearButton="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                                item-template="item"
                                field-template="field"
                            >
                                <template #field="{ data }">
                                    <DxTextBox
                                        :value="data ? data.HoTen : ``"
                                        :class="
                                            CheckDuDieuKienLaiXeDi_3 == 'HetHan'
                                                ? 'color-error-thong-tin-lai-xe'
                                                : CheckDuDieuKienLaiXeDi_3 == 'GanHetHan'
                                                ? 'color-warning-thong-tin-lai-xe'
                                                : ''
                                        "
                                    />
                                </template>
                                <template #item="{ data }">
                                    <div
                                        style="
                                            white-space: break-spaces;
                                            border-bottom: 1px solid #dadce0;
                                        "
                                    >
                                        {{ data ? `${data.HoTen}` : "" }}
                                    </div>
                                </template>
                                <DxValidator>
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[0]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[0];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                    <DxCompareRule
                                        v-if="
                                            ThongTinSuaChuyenDiKeHoach
                                                .DanhSachLaiXeThucHien[1]
                                        "
                                        :comparisonTarget="
                                            () => {
                                                return ThongTinSuaChuyenDiKeHoach
                                                    .DanhSachLaiXeThucHien[1];
                                            }
                                        "
                                        comparisonType="!="
                                        message="Thông tin lái xe không được trùng!"
                                    />
                                </DxValidator>
                            </DxSelectBox>
                        </div>

                        <DxButton
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-pencil"
                            :disabled="
                                !ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[2]
                            "
                            @click="
                                SuaLaiXe(
                                    ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien[2],
                                )
                            "
                        />
                    </div>

                    <div class="row mb-4">
                        <div class="xs12">
                            <DxTextBox
                                v-model="ThongTinSuaChuyenDiKeHoach.HoTenPhuXe"
                                label="Phụ xe"
                                labelMode="floating"
                                styling-mode="underlined"
                                validationMessageMode="always"
                            >
                                <DxValidator ref="validatePhuXe">
                                    <DxStringLengthRule
                                        :max="48"
                                        message="Phụ xe không được lớn hơn 48 ký tự!"
                                    />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>
                </DxValidationGroup>

                <div
                    class="row"
                    style="
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: #fff;
                    "
                >
                    <div class="xs12">
                        <div
                            class="row justify-space-between"
                            style="
                                border-top: 1px solid #dadce0;
                                padding: 0 10px;
                                height: 50px;
                                align-items: center;
                            "
                        >
                            <div class="text-xs-center">
                                <DxButton
                                    text="Hủy"
                                    type="danger"
                                    styling-mode="text"
                                    width="100%"
                                    class="color-danger"
                                    @click="$router.go(-1)"
                                />
                            </div>
                            <div class="text-xs-center">
                                <DxButton
                                    text="Chỉnh sửa kế hoạch"
                                    type="default"
                                    styling-mode="contained"
                                    class="color-primary"
                                    @click="ChinhSuaKeHoach()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    onIonViewWillEnter,
} from "@ionic/vue";
import {
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxTextArea,
    DxValidationGroup,
    DxValidator,
} from "devextreme-vue";
import {
    DxRequiredRule,
    DxCustomRule,
    DxStringLengthRule,
    DxCompareRule,
} from "devextreme-vue/data-grid";
import DxList from "devextreme-vue/list";
import PopupVue from "../../../components/_Common/Popup.vue";
export default {
    components: {
        DxButton,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        DxTextBox,
        DxSelectBox,
        DxTextArea,
        DxRequiredRule,
        DxStringLengthRule,
        DxCompareRule,
        DxCustomRule,
        DxValidationGroup,
        DxValidator,
        DxList,
        PopupVue,
        onIonViewWillEnter,
    },
    data() {
        return {
            DanhSachXeTrongDoi: [],
            DanhSachLaiXeTrongDoi: [],
        };
    },
    computed: {
        ThongTinSuaChuyenDiKeHoach: {
            get() {
                return this.$store.state.DVVTLenh.ThongTinSuaChuyenDiKeHoach;
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "ThongTinSuaChuyenDiKeHoach",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_ThongTinSuaLaiXe: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinSuaLaiXe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinSuaLaiXe",
                    data: data,
                });
            },
        },
    },
    watch: {},
    methods: {
        async LayDanhSachHangGPLX() {
            try {
                this.$startLoading;
                await this.$store.dispatch("ThongTinLaiXe/Get_DanhSachHangGPLX");
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách hạng GPLX bị lỗi! Lỗi hệ thống client.",
                );
            } finally {
                this.$stopLoading;
            }
        },
        async GetChiTietKeHoach() {
            this.$startLoading;
            let DsBienSoXe = [];
            let DanhSachLaiXeTrongDoi = [];
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "QuanLyLenh",
                    url: this.$t("urlDNQuanLyLenh.ChiTietKeHoachTuyenVersion2"),
                    dataGet: {
                        IdDoiXe: this.ThongTinSuaChuyenDiKeHoach.IdDoiXe,
                        IdDnvtTuyen: this.ThongTinSuaChuyenDiKeHoach.IdDnvtTuyen,
                        IdBenXuatPhat: this.ThongTinSuaChuyenDiKeHoach.IdBenDi,
                    },
                    msgSuccess: "",
                    msgError: "Lấy thông tin chi tiết kế hoạch thất bại!",
                    msgCatch: "Lấy thông tin chi tiết kế hoạch thất bại!",
                });
                if (rs.status) {
                    DsBienSoXe = rs.data.DanhSachXe;
                    DanhSachLaiXeTrongDoi = rs.data.DanhSachLaiXe;
                }
            } catch (error) {
                console.log("🚀 ", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy danh sách nhà xe, phương tiện và lái xe dự kiến thất bại!",
                );
                DsBienSoXe = [];
                DanhSachLaiXeTrongDoi = [];
            } finally {
                this.$stopLoading;
                this.DanhSachXeTrongDoi = this.$Helper.CloneData(DsBienSoXe);
                let itemXe = this.DanhSachXeTrongDoi.find(
                    (e) => e.IdDnvtXe == this.ThongTinSuaChuyenDiKeHoach.IdDnvtXe,
                );
                if (!itemXe) {
                    this.ThongTinSuaChuyenDiKeHoach.XeThucHien = null;
                } else {
                    this.ThongTinSuaChuyenDiKeHoach.XeThucHien = this.ThongTinSuaChuyenDiKeHoach.IdDnvtXe;
                }

                this.DanhSachLaiXeTrongDoi = this.$Helper.CloneData(
                    DanhSachLaiXeTrongDoi,
                );
                let DanhSachLaiXeThucHien = this.$Helper.CloneData(
                    this.ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien || [],
                );
                DanhSachLaiXeThucHien.reverse();
                DanhSachLaiXeThucHien.forEach((e) => {
                    let itemLX = this.DanhSachLaiXeTrongDoi.find(
                        (lx) => lx.IdDnvtLaiXe == e?.IdDnvtLaiXe,
                    );
                    this.ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien.unshift(itemLX);
                    this.ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien.pop();
                });
            }
        },
        SuaLaiXe(item) {
            this.KyLenhTuyChon_ThongTinSuaLaiXe.TenLaiXe = item.HoTen;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.IdLaiXe = item.IdLaiXe;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.IdDnvtLaiXe = item.IdDnvtLaiXe;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.HangGPLX = item.HangGiayPhep;
            this.KyLenhTuyChon_ThongTinSuaLaiXe.HanHieuLucGPLX = new Date(
                item.HanGiayPhep,
            );
            this.KyLenhTuyChon_ThongTinSuaLaiXe.NgayKetThucHopDong = new Date(
                item.NgayKetThucHopDong,
            );

            this.KyLenhTuyChon_ThongTinSuaLaiXe.NgayBatDauHopDong = new Date(
                item.NgayBatDauHopDong,
            );
            this.$router.push("/Tao-Lenh-Tuy-Chon/Sua-Thong-Tin-Lai-Xe");
        },
        async ChinhSuaKeHoach() {
            try {
                let validate = this.$refs.formValidation.instance.validate();
                if (!validate.isValid) {
                    return this.$Helper.Common.autoFocusError(validate);
                }

                let DanhSachLaiXeThucHien = this.ThongTinSuaChuyenDiKeHoach.DanhSachLaiXeThucHien.filter(
                    (e) => e.IdDnvtLaiXe,
                );
                let DataPost = {
                    IdKeHoach: this.ThongTinSuaChuyenDiKeHoach.ID_KeHoach,
                    IdDnvtXe: this.ThongTinSuaChuyenDiKeHoach.XeThucHien || null,
                    IdDnvtLaiXes: (DanhSachLaiXeThucHien || []).map((e) => e.IdDnvtLaiXe),
                    HoTenPhuXe: this.ThongTinSuaChuyenDiKeHoach.HoTenPhuXe,
                };

                let rs = await this.$Events.RequestPost({
                    keyRequest: "CapNhatKeHoach",
                    ServicesBE: "QuanLyLenh",
                    url: this.$t("urlDNQuanLyLenh.CapNhatKeHoach"),
                    dataPost: DataPost,
                    msgSuccess: "Chỉnh sửa thông tin chuyến đi kế hoạch thành công!",
                    msgError: "Chỉnh sửa thông tin chuyến đi kế hoạch thất bại!",
                    msgCatch: "Chỉnh sửa thông tin chuyến đi kế hoạch thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Popup",
                });
                if (rs.status) {
                    this.$router.go(-1);
                }
            } catch (error) {
                console.log("🚀 ~ ChinhSuaKeHoach ~ error:", error);
            }
        },
    },

    created() {
        onIonViewWillEnter(() => {
            this.LayDanhSachHangGPLX();
            this.GetChiTietKeHoach();
            let interval = setInterval(() => {
                let refTuyenVanChuyen = this.$refs.TuyenVanChuyen;
                let refTenDoiXe = this.$refs.TenDoiXe;
                if (refTenDoiXe) {
                    clearInterval(interval);
                    refTuyenVanChuyen.instance.repaint();
                    refTenDoiXe.instance.repaint();
                }
            }, 300);
        });
    },
    mounted() {},
};
</script>
<style lang="css">
.style-title {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
}
.p-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

.d-flex-c {
    display: flex;
    justify-content: center;
}
</style>
